
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function pageHeaderRT () {
    return _createElement('div', { 'className': 'cm_vehicle-widget' }, this.template === 'active' ? _createElement('div', {
        'className': 'cmTemplate_active',
        'key': '34'
    }, _createElement('h2', { 'className': 'collection-title text-left font-weight-normal mb-0' }, this.fitmentSearchTitle)) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label flex-nowrap align-items-center flex-column flex-md-row cmTemplate_locked',
        'key': '220'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button btn btn-primary',
        'onClick': this.openDialog
    }, window.themeSettings.locales.ymm.change), _createElement('div', {
        'className': 'cm_vehicle-widget_button btn btn-secondary',
        'onClick': this.discardVehicle
    }, [_createElement('svg', {
            'xmlns': 'http://www.w3.org/2000/svg',
            'className': 'cm_icon cm_icon-reset',
            'height': '16px',
            'viewBox': '0 0 16 16',
            'key': '8400'
        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))) : null);
}
        export const componentNames = []