
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabDesktopRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('a', {
        'className': 'nav-link d-flex',
        'id': 'product-tabs-tab-fitment',
        'data-toggle': 'tab',
        'href': '#product-tabs-content-fitment',
        'role': 'tab',
        'aria-controls': 'product-tabs-content-fitment',
        'aria-selected': 'true',
        'key': '0'
    }, _createElement('span', {}, window.themeSettings.locales.ymm.fitment_table_title || 'Fitment')) : null;
}
        export const componentNames = []