
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'xmlns': 'http://www.w3.org/2000/svg',
                'width': '28',
                'height': '28',
                'className': 'icon-search',
                'viewBox': '0 0 24 24',
                'fill': 'none',
                'stroke': 'currentColor',
                'strokeWidth': '1',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round'
            }, _createElement('circle', {
                'cx': '10.778',
                'cy': '10.778',
                'r': '9.778'
            }), _createElement('line', {
                'x1': '23',
                'y1': '23',
                'x2': '17.683',
                'y2': '17.683'
            })), _createElement('button', {
                'type': 'button',
                'className': 'btn btn-secondary',
                'data-cm-role': 'add-query'
            }, '\n  ', window.themeSettings.locales.search_button, '\n'));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]