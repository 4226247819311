
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxDialogRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog' }, _createElement('div', { 'className': 'clear-self cm_search-box_container' }, _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'd-flex dialog-content',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Search'), _createElement('div', {
        'className': 'cm_search-box-dialog_close-button close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'cm_search-box_form-container input-group' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'form-control bg-light h-100 p-3 cm_searchInput' }, window.themeSettings.locales.search_placeholder);
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '5982'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '948'
        }, '\n    ', window.innerWidth > 991 ? '\u2715' : window.themeSettings.locales.search_clear, '\n  ') : null),
        _createElement('div', {
            'className': 'cm_search-box_submit input-group-append h-100',
            'title': 'Search',
            'aria-label': 'search button',
            'data-cm-role': 'add-query',
            'key': '5984'
        }, [
            _createElement('svg', {
                'xmlns': 'http://www.w3.org/2000/svg',
                'width': '28',
                'height': '28',
                'className': 'icon-search',
                'viewBox': '0 0 24 24',
                'fill': 'none',
                'stroke': 'currentColor',
                'strokeWidth': '1',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round',
                'key': '12850'
            }, _createElement('circle', {
                'cx': '10.778',
                'cy': '10.778',
                'r': '9.778'
            }), _createElement('line', {
                'x1': '23',
                'y1': '23',
                'x2': '17.683',
                'y2': '17.683'
            })),
            _createElement('button', {
                'type': 'button',
                'className': 'btn btn-secondary',
                'data-cm-role': 'add-query',
                'key': '12852'
            }, '\n  ', window.themeSettings.locales.search_button, '\n')
        ])
    ]), [this.dropdown(function () {
            function repeatSections1(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_text(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function onError1_facetValue(e) {
                                        e.target.classList.add('cm_hide');
                                    }
                                    function mergeProps_facetValue(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                        'className': window.themeSettings.img_border === 'true' ? 'img-thumbnail' : 'rounded',
                                        'src': this.imageOrDefault(this.resizeImage(this.image)),
                                        'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.title)),
                                        'onError': this.onImageError
                                    })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'text-secondary' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('p', { 'className': 'product-price-container mb-0' }, this.on_sale ? _createElement('s', {
                                        'className': 'text-secondary mr-2',
                                        'key': '520'
                                    }, this.formatPrice(this.compare_at_price)) : null, _createElement('span', { 'className': 'product-price ' + (this.compare_at_price && this.compare_at_price ? 'text-primary' : 'text-secondary') }, '\n      ', this.formatPrice(this.price), '\n    ')))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                        'className': 'image-block',
                                        'key': '35'
                                    }, _createElement('img', {
                                        'src': this.imageUrl,
                                        'alt': 'Image of ' + this.facetValue,
                                        'onError': onError1_facetValue.bind(this)
                                    })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                        'className': 'hit-count',
                                        'key': '335'
                                    }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') }, this.sectionTitle ? _createElement('div', {
                            'className': 'section-title-div',
                            'key': '94'
                        }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'dialog-body cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_sections' },
                _map(this.sections, repeatSections1.bind(this))
            ]), this.browseAllButton ? [[this.browseAllButton(function () {
                        return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, window.themeSettings.locales.browse_all, ' (', this.totalHits, ')');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]] : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]