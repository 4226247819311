
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabMobileRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('h2', {
        'className': 'mb-0',
        'key': '0'
    }, _createElement('button', {
        'className': 'btn btn-link btn-block text-left collapsed',
        'type': 'button',
        'data-toggle': 'collapse',
        'data-target': '#product-accordion-collapse-fitment-table',
        'aria-expanded': 'true',
        'aria-controls': 'product-accordion-collapse-fitment-table'
    }, '\n    ', window.themeSettings.locales.ymm.fitment_table_title || 'Fitment', '\n  ')) : null;
}
        export const componentNames = []